<template>
  <div id="collect" class="world">
    <ul class="tab flex">
      <li
        v-for="(item, index) in tab"
        :key="index"
        :class="{ active: active == index }"
        @click="tabs(index)"
      >
        {{ item }}
      </li>
    </ul>

    <div class="cursorP">
      <ul
        class="list work loads"
        v-if="active == 0"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
      >
        <li class="box" v-for="item in recomdList" :key="item.id">
          <div class="top flex">
            <div class="left">
              <router-link
                :to="'/job/jobDetail/?id=' + item.id"
                target="_blank"
              >
                <h3>
                  {{ item.jobName }}
                  <small>「{{ item.updateTime }}」</small>
                </h3>
              </router-link>
              <p>
                <span class="money" v-if="item.jobSalary">{{
                  item.jobSalary
                }}</span>
                <span class="money" v-else>面议</span>
                <span v-if="item.provinceid">
                  {{ item.provinceid }}
                  <i></i>
                </span>
                <span v-else>
                  地区暂无
                  <i></i>
                </span>
                <span v-if="item.edu">
                  {{ item.edu }}
                  <i></i>
                </span>
                <span v-else>
                  学历暂无
                  <i></i>
                </span>
                <span v-if="item.exp">
                  {{ item.exp }}
                  <i></i>
                </span>
                <span v-else>
                  工作经验暂无
                  <i></i>
                </span>
                <span v-for="(n, i) in item.jobDistypeOutDTOList" :key="i">
                  {{ k.disType }}
                  <i v-if="k.disTypeLevel"></i>
                  {{ k.disTypeLevel }}
                </span>
              </p>
            </div>
            <div class="right flex" v-if="item.companyInfo">
              <div class="info">
                <router-link
                  :to="'/businessDetails/?companyId=' + item.companyId"
                  target="_blank"
                >
                  <h3>{{ item.companyInfo.companyName }}</h3>
                </router-link>
                <p>
                  <span v-if="item.companyInfo.hyTop">
                    {{ item.companyInfo.hyTop }}
                    <i></i>
                  </span>
                  <span v-else>
                    行业类型暂无
                    <i></i>
                  </span>
                  <span v-if="item.companyInfo.pr">
                    {{ item.companyInfo.pr }}
                    <i></i>
                  </span>
                  <span v-else>
                    公司类型暂无
                    <i></i>
                  </span>
                  <span v-if="item.companyInfo.mun">
                    {{ item.companyInfo.mun }}
                    <i></i>
                  </span>
                  <span v-else>
                    要求人数若干
                    <i></i>
                  </span>
                </p>
              </div>
              <img
                :src="item.companyInfo.logo"
                alt
                :title="item.companyInfo.companyName"
                class="logo img err_logo"
              />
            </div>
          </div>
          <div class="bottom flex">
            <div class="left" v-if="item.jobLabels.length > 0">
              <span v-for="(n, i) in item.jobLabels" :key="i">{{
                n.labelName
              }}</span>
            </div>
            <div class="left" v-else>
              <span>暂无福利标签</span>
            </div>
            <!-- <div class="right">
              <span>简历处理速度</span>
              <div class="schedule">
                <div :style="{width:item.companyInfo.vate+'%'}"></div>
              </div>
              <span>{{item.companyInfo.vate}}%</span>
            </div>-->
          </div>
        </li>
      </ul>
      <!-- 培训 -->
<!--      <ul-->
<!--        class="list train flex-wrap loads"-->
<!--        v-if="active == 1"-->
<!--        v-loading="loading"-->
<!--        element-loading-text="拼命加载中"-->
<!--        element-loading-spinner="el-icon-loading"-->
<!--      >-->
<!--        <li class="box" v-for="item in trainList" :key="item.id">-->
<!--          <router-link-->
<!--            :to="'/training/trainDetail/?id=' + item.id"-->
<!--            target="_blank"-->
<!--          >-->
<!--            <div class="banner relative">-->
<!--              <img :src="item.picurl" alt class="err_image imageLogo"-->
<!--             :class="item.askPhoto ? 'imageLogo' : 'err_avatar'"-->
<!--              />-->
<!--              &lt;!&ndash; <img src="../../static/6.jpg" alt class="err_logo" v-else/> &ndash;&gt;-->
<!--              <span class="type" v-if="item.isTui">精选</span>-->
<!--            </div>-->
<!--            <div class="info">-->
<!--              <h2>{{ item.trainName }}</h2>-->
<!--              <p class="timing">-->
<!--                报名时间：{{ item.appplyBeginTime }}-{{ item.appplyEndTime }}-->
<!--              </p>-->
<!--              <p class="timing">-->
<!--                培训时间：{{ item.trainBeginTime }}-{{ item.trainEndTime }}-->
<!--              </p>-->
<!--              <p class="applys">-->
<!--                已报名-->
<!--                <span>{{ item.applyNumber }}人</span>-->
<!--                共招-->
<!--                <span>{{ item.maxNumber }}</span-->
<!--                >人-->
<!--              </p>-->
<!--            </div>-->
<!--          </router-link>-->
<!--        </li>-->
<!--      </ul>-->
      <!-- 公开课 -->
      <ul
        class="list train public flex-wrap loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-if="active == 1"
      >
        <li class="box hights" v-for="(item, index) in courseList" :key="index">
          <router-link
            :to="'/training/publicDetail/?id=' + item.id"
            target="_blank"
          >
            <div class="banner flexs">
              <img :src="item.imgurl" alt class="err_image" />
            </div>
            <div class="info">
              <h2 class="line_clamp1">{{ item.trainName }}</h2>
              <p class="applys marT10">
                <span>{{ item.reamark }}章节</span>
                <span>{{ item.studys }}</span
                >人已学
              </p>
            </div>
          </router-link>
        </li>
      </ul>
      <div
        v-if="active == 3"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        class="loads"
      >
        <Like :goodList="goodList" @collects="collects" :types="types"></Like>
        <div v-if="status.isHide == 4 && this.active == 3">
          <status :type="status.type" :des="status.des"></status>
        </div>
      </div>
      <ul
        class="list activity"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-if="active == 4"
      >
        <li
          class="box relative"
          v-for="(item, index) in activeList"
          :key="index"
        >
          <div class="banner">
            <img :src="item.jsonObject.imgUrl" alt="" />
          </div>
          <div class="info">
            <h3><router-link :to="'/activity_outline/activity_details?id='+item.activityId" target="_blank">{{item.activityName}}</router-link></h3>
            <!--            <p class="first">1人已报名</p>-->
            <p>
              活动时间： {{ item.jsonObject.activityStartTime }} 至
              {{ item.jsonObject.activityEndTime }}
            </p>
            <p>活动地点： {{ item.jsonObject.activityAddress }}</p>
          </div>
          <div
            class="buttons"
            @click="collection(item.activityId, item.activityName)"
          >
            <img src="~/static/job/collected.png" alt="" />
            取消收藏
          </div>
        </li>
      </ul>
      <div
        v-if="
          activeList == null || (activeList.length == 0 && this.active == 4)
        "
      >
        <status :type="status.type" :des="status.des"></status>
      </div>
    </div>

    <div class="pages">
      <el-pagination
        background
        class="work_pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="params.pageNum"
        :page-size="params.pageSize"
        layout="prev, pager, next, total"
        prev-text="上一页"
        next-text="下一页"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import Like from "components/center/like";

export default {
  name: "collect",
  components: { Like },
  data() {
    return {
      active: 0,
      types: 1,
      loading: false,
      goodList: [],
      recomdList: [],
      trainList: [],
      courseList: [],
      activeList: [],
      status: {
        isHide: 0,
        type: "",
        des: "",
      },
      tab:  ["职位", "公开课"],
      total: 0,
      params: {
        pageNum: 1,
        pageSize: 8,
      },
      collect: false,
      location:'',
    };
  },
  methods: {
    tabs(i) {
      this.active = i;
      this.location = localStorage.getItem('locationSite');
      if (this.active === 0) {
        this.querySearchJobInfo();
      } else if (this.active === 1) {
        this.getCollectFwjgTrain();
      } else if (this.active === 2) {
        this.getMycollectCourse();
      } else if (this.active === 3) {
        this.querycollectClomun();
      } else {
        this.querycollectActive();
      }
    },

    // 职位收藏列表
    async querySearchJobInfo() {
      this.loading = true;
      try {
        let res = await this.$api.queryCollectionsJob(this.params);
        if (res.data.success) {
          this.recomdList = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;

          document.title = "我收藏的职位";
          if (this.recomdList.length <= 0) {
            this.status.isHide = 1;
            this.status.type = "post";
            this.status.des = "暂无职位收藏！";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //培训收藏
    async getCollectFwjgTrain(index) {
      this.loading = true;
      try {
        let res = await this.$api.getCollectFwjgTrain(this.params);
        if (res.data.success) {
          this.trainList = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          document.title = "我收藏的培训";
          if (this.trainList.length <= 0) {
            this.status.isHide = 2;
            this.status.type = "post";
            this.status.des = "暂无培训收藏！";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 公开课收藏
    async getMycollectCourse(index) {
      this.loading = true;
      try {
        let res = await this.$api.getMycollectCourse(this.params);
        if (res.data.success) {
          this.courseList = res.data.data.records;
          document.title = "我收藏的公开课";
          this.total = res.data.data.total;
          this.loading = false;
          if (this.courseList.length <= 0) {
            this.status.isHide = 3;
            this.status.type = "post";
            this.status.des = "暂无公开课收藏！";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //活动收藏
    async querycollectActive() {
      this.loading = true;
      let res = await this.$api.queryCollectionActivity(this.params);
      if (res && res.data && res.data.success) {
        this.activeList = res.data.data.records;
        this.total = res.data.data.total;
        if (this.activeList.length <= 0) {
          this.status.type = "post";
          this.status.des = "暂无活动收藏！";
        } else {
          this.status.isHide = 0;
        }
        document.title = "我收藏的活动";
        this.loading = false;
        // TODO 无数据显示信息处理
      }
    },
    // 专栏收藏
    async querycollectClomun() {
      this.loading = true;
      try {
        let res = await this.$api.querycollectClomun(this.params);
        console.log("我点赞的", res);
        if (res.data.success) {
          this.goodList = res.data.data.records;
          this.total = res.data.data.total;
          document.title = "我收藏的专栏";
          this.loading = false;
          if (this.goodList.length <= 0) {
            this.status.isHide = 4;
            this.status.type = "post";
            this.status.des = "暂无专栏收藏！";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //收藏
    collectFn(id, type) {
      this.$api.saveClomunCollectionApi({ id: id }).then((res) => {
        type.collections = 1 ^ type.collections;
        if (type.collections) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 子组件收藏
    collects(index) {
      console.log(index);
      let info = this.goodList[index];
      console.log(info.id, info.userInfo);
      this.collectFn(info.id, info.userInfo);
    },
    goRouter(url, memberId) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: memberId,
          userType: userType,
        },
      });
      window.open(href, "_blank");
    },
    //收藏
    collection(id, name) {
      let params = {
        id: id,
        activityName: name,
      };
      this.$confirm("确认取消收藏？")
        .then((_) => {
          this.$api.collectActivity(params).then((res) => {
            this.$message.error("取消收藏");
            this.querycollectActive();
          });
        })
        .catch((_) => {});
    },
    //分页
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.tabs(this.active);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.tabs(this.active);
    },
  },
  created() {
    if (this.$route.query.active) {
      this.active = this.$route.query.active;
    }
    this.tabs(this.active);
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/center/center.less";

.applys {
  display: flex;

  span:first-child {
    flex: 1;
  }
}

.timing {
  color: #666666;
  font-size: 12px;
}

.status {
  margin-top: 25px;
}

.pages {
  padding-top: 90px;
}

/deep/ .el-loading-mask {
  top: 28px;
  right: 70px;
}

/deep/ .el-loading-spinner {
  top: 5%;
}
.imageLogo{
  width: 300px;
  height: 300px;
}
</style>

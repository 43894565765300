<template>
  <div>
    <!-- {{goodsList}} -->
    <div v-for="(item,index) in goodList" :key="index">
      <h4>{{item.name}}</h4>
      <div class="txt">
        <div class="imgs">
          <img :src="item.reamark1" alt />
        </div>
        <p class="text line_clamp3">
          {{excision(item.gaidesc)}}
          <router-link
            :to="{path:'/section/sectionDetail', query: {id: item.id }}"
            :title="item.name"
            target="_blank"
          >
            <span class="more">阅读全文</span>
          </router-link>
        </p>
      </div>
      <div class="feature">
        <ul class="flex left">
          <li>
            <i class="el-icon-view"></i>
            {{item.clicks}} 阅读
          </li>
          <li>
            <i class="el-icon-chat-dot-square"></i>
            {{item.jsonObject.recomands}} 评论
          </li>
          <li
            class="praise"
            :class="item.userInfo.goods == '0'?'':'success'"
            @click="collectCloumn(index)"
          >
            <img src="~/static/ask/like.png" alt class="img" />
            <img src="~/static/ask/liked.png" alt class="green" />
            {{item.jsonObject.goods}} 点赞
          </li>
          <li
            v-if="types"
            class="praise"
            :class="item.userInfo.collections == '0'?'':'success'"
            @click="collects(index)"
          >
            <img src="~/static/ask/collection.png" alt class="img" />
            <img src="~/static/ask/collectioned.png" alt class="green" />
            收藏
          </li>
        </ul>
        <p>#{{item.fenclass}}</p>
      </div>
      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
export default {
  props: ["goodList", "types"],
  data() {
    return {
      more: 0,
    };
  },
  methods: {
    collectCloumn(index) {
      console.log(index);
      this.$emit("collectCloumns", index);
    },
    collects(index) {
      console.log(index);
      this.$emit("collects", index);
    },
    // 控制字体长度
    excision(text) {
      if (text) {
        if (text.length > 270) {
          return text.slice(0, 270) + "...";
        } else {
          this.more = 3;
        }
        return text;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  align-items: center;

  .imgs {
    width: 50px;
    height: 50px;
    overflow: hidden;

    img {
      width: auto;
      height: 100%;
      border-radius: 50%;
    }
  }

  .rights {
    margin-left: 15px;

    .p1 {
      color: #333333;
      font-size: 14px;
    }

    .p2 {
      font-size: 14px;
      color: #666666;
    }
  }
}

h4 {
  color: #333333;
  margin-top: 15px;
}

.txt {
  align-items: center;
  margin-top: 15px;
  display: flex;

  .imgs {
    max-width: 190px;
    max-height: 105px;
    overflow: hidden;
    border-radius: none;
    margin-right: 9px;

    img {
      width: 100%;
      height: 100%;
      border-radius: none;
    }
  }

  .text {
    flex: 1;
    font-size: 14px;
    color: #666666;
  }
}

.feature {
  margin-top: 10px;
  display: flex;

  .left {
    flex: 1;
    cursor: pointer;

    li {
      margin-right: 20px;
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 14px;
      i {
        margin-right: 3px;
        font-size: 16px;
        color: #cccccc;
      }
      img {
        margin-right: 3px;
      }
    }

    .praise {
      cursor: pointer;

      .img {
        display: block;
      }

      .green {
        display: none;
      }
    }

    .success {
      color: #00924c;

      .img {
        display: none;
      }

      .green {
        display: block;
      }
    }
  }
}
.more {
  color: #00924c;
  text-decoration: underline;
  font-size: 14px;
}
</style>